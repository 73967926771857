import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"

import symptomsGraphic from "../assets/images/symptoms.png"
import arrowRight from '../assets/images/video-arrow-white.svg'
const HomeInfographic = () => {
  return (
    <Container
      css={css`
          h2 {
            color: #69696a;
            font-size: 30px;
            font-weight: bold;
            line-height: 34px;
          }
          a {
            &:hover {
              text-decoration: none;
            }
          }
          ${[mq[2]]} {
            margin-bottom: 80px;
            h2 {
              font-size: 20px;
              line-height: 21px;
            }
          }
          // margin-bottom: 150px;
        `}
    >

      <Row>
        <Col
          lg={6}
          css={css`
              color: #646565;
              ${[mq[2]]} {
                text-align: center;
              }
            `}
        >
          <a href="/about"
            target="_self"
            rel="noopener noreferrer"
          >
            <h2 css={css`
                    padding-top: 60px;
                `}>
              HODGKIN LYMPHOMA TREATMENT
            </h2>
            <p
              css={css`
                  color: #646565;
                  font-family: Arial;
                  font-size: 18px;
                  letter-spacing: 0;
                  line-height: 24px;
                `}
            >
              Hodgkin lymphoma is a cancer that starts in white blood cells called lymphocytes, which are part of the body's immune system.<sup>2</sup>
            </p>
            <p
              css={css`
                  color: #646565;
                  font-family: Arial;
                  font-size: 18px;
                  letter-spacing: 0;
                  line-height: 24px;
                `}
            >
              Across all types, Hodgkin lymphoma has a 5-year relative survival rate of 89%. Treatment is dependent upon the stage of Hodgkin lymphoma.<sup>3</sup>
            </p>
            <p
              css={css`
                  color: #646565;
                  font-family: Arial;
                  font-size: 12px;
                  letter-spacing: 0;
                  line-height: 16px;
                `}
            >
              5-year relative survival rate is the percentage of people with a type of cancer who are alive 5 years after treatment compared to the total US population. If the relative 5-year survival rate is 89% for all stages of Hodgkin lymphoma, that means patients diagnosed with Hodgkin lymphoma are 89% as likely as those without that cancer to still be alive 5 years after being diagnosed.<sup>3</sup>
            </p>
            <div
              css={css`
                          display: flex;
                          ${[mq[2]]} {
                            justify-content: center;
                          }
                        `}>
              <p
                css={css`
                    color: #804095;
                    margin-right: 10px;
                    font-family: Arial;
                    margin-top: 6px;
                    margin-bottom: 90px;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22px;
                    &:hover {
                      border-bottom: 2px solid #804095;
                    }
                 `}>
                LEARN MORE</p>
              <div
                css={css`
                            background: linear-gradient(180deg, #7E3F97 0%, #AE58CF 100%);
                            border-radius: 18px;
                            height: 32px;
                            width: 32px;
                            text-align: center;
                        `}
              >
                <img
                  css={css`
                            height: 15px;
                            width: 11px;
                            margin: 8px 8px 8px 10px;
                         `}
                  loading='lazy' src={arrowRight} alt='Learn More Arrow' />
              </div>
            </div>
          </a>
        </Col>
        <Col
          lg={6}
          css={css`
            `}
        >
          <div className="flip-card" css={css`               
                background-color: transparent;
                width: 100%;
                // height: 350px;
                // perspective: 1000px;
                // &:hover .flip-card-inner{
                //   transform: rotateY(180deg);
                // }
                // .flip-card:hover .flip-card-inner {
                //   transform: rotateY(180deg);
                // }
                }
            `}>
            {/* <p css={css`
                  display: none;
                  text-align: center;
                  color: #01aef1;
                  font-size: 18px;
                  line-height: 21px;
                  margin-bottom: 0;
                  ${[mq[2]]} {
                    display: block;
                  }
                `}
            >
              Click the card to learn more
            </p> */}
            <div className="flip-card-inner" css={css`               
              position: relative;
              width: 100%;
              // height: 100%;
              text-align: center;
              // transition: transform 0.8s;
              // transform-style: preserve-3d;
            `}>

              {/* <div className="flip-card-front" css={css`
                position: absolute;
                width: 100%;
                height: 100%;
                -webkit-backface-visibility: hidden; 
                backface-visibility: hidden;
                display: flex;
                background: linear-gradient(90deg,#7E3F97 56%,#AE58D0 91%);
                margin-top: 40px;
                h2 {
                  color: #FFFFFF;
                  font-family: Arial;
                  font-size: 39px;
                  margin-top: 95px;
                  font-weight: 600;
                  letter-spacing: 0;
                  text-align: left;
                  line-height: 40px;
                }
                @media only screen and (max-width: 1186px) {
                  h2 {
                    font-size: 30px;
                  }
                }
                @media only screen and (max-width: 500px) {
                  h2 {
                    font-size: 25px;
                  }
                }
            `}>

                <img
                  loading='lazy'
                  css={css`
                  margin: 35px 30px;
                  width: 200px;
                  ${[mq[2]]} {
                    width: 200px;
                  }
                  @media only screen and (max-width: 500px) {
                    margin: 10px;
                  }
                `}
                  alt="symptoms"
                  src={patientGraphic}
                />
                <h2>WHAT ARE THE SYMPTOMS OF HODGKIN LYMPHOMA?</h2>
              </div> */}
              <div className="flip-card-back" css={css`
                // position: absolute;
                width: 100%;
                // height: 100%;
                // -webkit-backface-visibility: hidden; 
                // backface-visibility: hidden;
                background-color: #F0F0F1;
                text-align: center;
                margin-top: 40px;
                padding-top: 30px;
                // transform: rotateY(180deg);
            `}>
                <h2 css={css`padding: 0 1rem;`}>WHAT ARE SOME SYMPTOMS <br />OF HODGKIN LYMPHOMA?</h2>
                {/* <p>SYMPTOMS INCLUDE</p> */}
                <section
                  css={css`
                    display: block;
                    padding-top: 10px;
                    ${[mq[2]]} {
                      display: flex;
                      flex-direction: column;
                      padding: 20px 0 0;
                      align-items: center;
                    }
                  `}
                >
                  <img
                    loading='lazy'
                    css={css`
                  
                  ${[mq[2]]} {
                    max-width: 100%;
                  }
                  @media only screen and (max-width: 1186px) {
                    width: 375px;
                  }
                  @media only screen and (max-width: 500px) {
                    width: 330px;
                    margin-top: 10px;
                  }
                `}
                    alt="symptoms"
                    src={symptomsGraphic}
                  />
                  <div
                    css={css`
                          display: flex;
                          margin-top: 45px;
                          justify-content: center;
                        `}>

                    <a href="/about"
                      target="_self"
                    // rel="noopener noreferrer"
                    >
                      <p
                        css={css`
                    color: #804095;
                    margin-right: 10px;
                    font-family: Arial;
                    margin-top: 6px;
                    margin-bottom: 40px;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22px;
                    &:hover {
                      border-bottom: 2px solid #804095;
                    }
                 `}>
                        LEARN MORE</p>
                    </a>
                    <div
                      css={css`
                            background: linear-gradient(180deg, #7E3F97 0%, #AE58CF 100%);
                            border-radius: 18px;
                            height: 32px;
                            width: 32px;
                            text-align: center;
                        `}
                    >
                      <img
                        css={css`
                            height: 15px;
                            width: 11px;
                            margin: 8px 8px 8px 10px;
                         `}
                        loading='lazy' src={arrowRight} alt='Learn More Arrow' />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

// HomeInfographic.propTypes = {

// }

export default HomeInfographic